<template>
	<div>
		<a-row :gutter="10">
      <a-col :span="6">
        <layerTree @select="treeSelect"></layerTree>
      </a-col>
      <a-col :span="18">
        <ax-table
					ref="roleTable"
					:show-search="true"
					:searchForm="searchForm"
					:searchActions='searchActions'
					:columns="columns"
					:dataSourceApi="api.baseList"
					:dataSourceParams="dataSourceParams"
					:sqlParams="sqlParams"
					:clickable="false"
					:scroll="{ y: '55vh', x: '80vw' }"
					@pop-confirm="popConfirm"
					@add="handleAdd"
					@action-column-click="actionColumnClick"
				>
				</ax-table>

				<addFile ref="addFile" @on-ok="handleOk" :categoryId="categoryId"></addFile>
      </a-col>
    </a-row>
	</div>
</template>

<script>
import layerTree from '../component/layerTree.vue'
import addFile from '../component/addFile.vue'
import api from '../api.js'
const searchForm = [
  { label: '文件名称', type: 'input', model: 'name', options: {}, formItem: {}, col: { span: 6 } }
]
export default {
  components: {
    layerTree,
    addFile
  },
  data () {
    return {
      api,
      dataSourceParams: {
        categoryId: ''
      },
      categoryId: '',
      sqlParams: { like: ['name'] },
      searchActions: [
        { name: 'add', text: '新增文件' },
        { name: 'search', text: '查询' },
        { name: 'reset', text: '重置' }
      ],
      searchForm: this.$common.initGridFormData(searchForm, { layout: 'horizontal', labelWidth: 80 }),
      columns: this.$ax.tools.initColumn([
        {
          title: '文件名称',
          dataIndex: 'name'
        },
        {
          title: '说明',
          dataIndex: 'remarks'
        },
        {
          title: '图层类型',
          dataIndex: 'type_dictText'
        },
        {
          title: '年份',
          dataIndex: 'yearTime'
        }
      ], true, {
        width: 140,
        actions: [
          { text: '编辑', name: 'edit', type: 'primary', link: true },
          {
            props: () => {
              return { text: '删除', name: 'del', type: 'primary', link: true, popDisabled: false, title: '确认删除？' }
            }
          },
          {
            props: () => {
              return { text: '预览', name: 'preview', type: 'primary', link: true }
            }
          }
        ]
      })
    }
  },
  methods: {
    popConfirm ({ record, btn: { name } }) {
      if (name === 'del') {
        api.basedel({ id: record.id }).then(() => {
          this.$message.success('删除成功')
          this.handleOk()
        })
      }
    },
    handleAdd ({ btn }) {
      if (btn.name === 'add') {
        if (!this.categoryId) {
          this.$message.error('请先选择左侧图层节点')
          return
        }
        this.$refs.addFile.open()
      }
    },
    actionColumnClick ({ btn: { name }, record }) {
      if (name === 'edit') {
        this.$refs.addFile.open(record)
      }
    },
    // tree 选择
    treeSelect (data) {
      this.categoryId = data.id
      this.dataSourceParams.categoryId = data.id
      this.$refs.roleTable.getDataSource({ categoryId: data.id })
    },
    // 图层添加
    handleOk () {
      this.$refs.roleTable.getDataSource({ categoryId: this.categoryId })
    }
  }
}
</script>

<style>

</style>
